import { ExpandMore, FavoriteBorder, LocationOn, Search, Whatshot } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActionArea,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import PreviewCard from "../components/PreviewCard";
import PublicLayout from "../layout/PublicLayout";

const MORE = [
  {
    name: "Burrito Bros",
    type: "RESTAURANT",
    caption: "We are burrito bros!",
    image: require("../assets/burrito.jpg")
  },
  {
    name: "Dimsum Door",
    type: "RESTAURANT",
    caption: "We are Dimsum dooor!",
    image: require("../assets/citrus.jpg")
  },
  {
    name: "Curry Court",
    type: "RESTAURANT",
    caption: "We are Curry court!",
    image: require("../assets/egg.jpg")
  },
  {
    name: "Pizza Palace",
    type: "RESTAURANT",
    caption: "We are Pizza palace!",
    image: require("../assets/sandwich.jpg")
  },
  {
    name: "Burrito Bros",
    type: "RESTAURANT",
    caption: "We are burrito bros!",
    image: require("../assets/signin.jpg")
  },
  {
    name: "Dimsum Door",
    type: "RESTAURANT",
    caption: "We are Dimsum dooor!",
    image: require("../assets/burrito.jpg")
  },
  {
    name: "Curry Court",
    type: "RESTAURANT",
    caption: "We are Curry court!",
    image: require("../assets/sandwich.jpg")
  }
];

const FindRestaurants = () => {
  const { classes } = useStyles();
  return (
    <PublicLayout seoTitle="Find Restaurants">
      <Typography variant="h4">Restaurants that you'll love</Typography>
      <Typography variant="body2" className={classes.margin}>
        Browse the restaurants currently open for hire
      </Typography>

      <Grid container spacing={2} className={classes.margin}>
        <Grid item>
          <Button variant="outlined">Date Posted</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined">Experience Level</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined">Company</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined">All Filters</Button>
        </Grid>
      </Grid>

      <FormControl fullWidth className={classes.margin}>
        <TextField
          label="Search Restaurants"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      <Grid container spacing={6} className={classes.margin}>
        <FilterCard
          title="Restaurants Near You"
          description="Find restaurants close to your current location"
          icon={LocationOn}
        />
        <FilterCard
          title="Your Restaurant Matches"
          description="Know more about the restaurants mission and vibe"
          icon={Whatshot}
        />
        <FilterCard
          title="Top Picks"
          description="Top best restaurants in 2020 you need to visit"
          icon={FavoriteBorder}
        />
      </Grid>

      <Grid container spacing={4}>
        {MORE.map((restaurant, i) => (
          <Grid item xs={6} sm={4} md={3} key={i}>
            <PreviewCard
              key={i}
              image={restaurant.image}
              subtitle={restaurant.type}
              title={restaurant.name}
              description={restaurant.caption}
            />
          </Grid>
        ))}
      </Grid>
    </PublicLayout>
  );
};

const FilterCard = ({ title, description, icon: Icon }: any) => {
  const { classes } = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <div className={classes.filterCardWrapper}>
        <Card component={CardActionArea} square className={classes.filterCard} raised>
          <Container>
            <Icon className={classes.largeIcon} />
            <Typography variant="h6" noWrap>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.filterCardDescription}>
              {description}
            </Typography>
            <ExpandMore className={classes.medIcon} />
          </Container>
        </Card>
      </div>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  margin: {
    marginBottom: theme.spacing(3)
  },
  filterCardWrapper: {
    position: "relative",
    height: 0,
    paddingTop: "90%"
  },
  filterCard: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    "&:hover": {
      // TODO: update ref
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
      "& $largeIcon": {
        fill: "#FFFFFF"
      },
      "& $medIcon": {
        fill: "#FFFFFF"
      }
    }
  },
  largeIcon: {
    paddingTop: "10%",
    height: "35%",
    width: "35%",
    fill: theme.palette.primary.main
  },
  medIcon: {
    height: 50,
    width: 50,
    fill: theme.palette.primary.main
  },
  filterCardDescription: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

export default FindRestaurants;
